import React from 'react';

const HandHeart: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_47_997)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 12C0 11.4477 0.447715 11 1 11H2.04762C2.72279 11 3.84473 11.1265 4.96405 11.4695C5.90498 11.758 6.98358 12.2415 7.75363 13.055H10.4286C11.799 13.055 13.1171 13.5887 14.0919 14.5449C15.0674 15.5016 15.619 16.8034 15.619 18.165C15.619 18.4302 15.5137 18.6846 15.3262 18.8721C15.1386 19.0596 14.8843 19.165 14.619 19.165H8.33333C7.78105 19.165 7.33333 18.7173 7.33333 18.165C7.33333 17.6127 7.78105 17.165 8.33333 17.165H13.4496C13.2951 16.7214 13.038 16.3126 12.6915 15.9727C12.0946 15.3873 11.2809 15.055 10.4286 15.055H7.28571C6.96272 15.055 6.65961 14.899 6.47191 14.6361C6.09108 14.1028 5.32871 13.6732 4.37793 13.3817C3.45413 13.0986 2.53007 13 2.04762 13H2V18.5691L8.31996 21.6679C9.05636 22.0292 9.90846 22.0989 10.6972 21.8611L21.7201 18.5342C21.5797 18.1249 21.311 17.7654 20.9478 17.5086C20.5828 17.2504 20.1418 17.11 19.6874 17.11C19.6874 17.11 19.6875 17.11 19.6874 17.11H17.7619C17.2096 17.11 16.7619 16.6623 16.7619 16.11C16.7619 15.5577 17.2096 15.11 17.7619 15.11H19.6874C20.5525 15.1101 21.3975 15.377 22.1026 15.8757C22.8079 16.3744 23.3386 17.0807 23.6158 17.8966C23.6158 17.8967 23.6157 17.8966 23.6158 17.8966L23.9468 18.8707C24.0341 19.1275 24.0133 19.4087 23.8893 19.6498C23.7652 19.891 23.5486 20.0715 23.2889 20.1498L11.2748 23.7759C10.0036 24.1592 8.63051 24.0479 7.43947 23.4636C7.43939 23.4636 7.43954 23.4637 7.43947 23.4636L0.559752 20.0904C0.217146 19.9224 0 19.5741 0 19.1925V12Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.7995 0.287073C13.4091 0.0419317 14.065 -0.048831 14.7161 0.024903C15.367 0.0986073 15.9879 0.333746 16.5329 0.705351C16.6972 0.817332 16.8531 0.940713 17 1.0743C17.1469 0.940713 17.3028 0.817332 17.4671 0.705351C18.0121 0.333746 18.633 0.0986073 19.2839 0.024903C19.935 -0.048831 20.5909 0.0419318 21.2005 0.287073C21.8095 0.531956 22.3514 0.922302 22.7904 1.42059C23.5752 2.31084 24 3.48751 24 4.69457C24 5.90174 23.5751 7.07852 22.7902 7.96881L22.7888 7.97037L17.7487 13.6629C17.5589 13.8773 17.2863 14 17 14C16.7137 14 16.4411 13.8773 16.2513 13.6629L11.2112 7.97037L11.2098 7.96881C10.4249 7.07852 10 5.90175 10 4.69457C10 3.4875 10.4248 2.31084 11.2096 1.42059M22.0393 2.08231L21.2898 2.74273C21.0478 2.46806 20.7607 2.26587 20.4543 2.14266C20.1486 2.01971 19.8262 1.97627 19.5089 2.0122C19.1913 2.04816 18.8784 2.16373 18.5937 2.35783C18.3084 2.55233 18.0573 2.82201 17.8654 3.15343C17.6866 3.46224 17.3568 3.65238 17 3.65238C16.6432 3.65238 16.3134 3.46224 16.1346 3.15343C15.9427 2.82201 15.6916 2.55233 15.4063 2.35783C15.1216 2.16373 14.8087 2.04816 14.4911 2.0122C14.1738 1.97627 13.8514 2.01971 13.5457 2.14266C13.2393 2.26587 12.9522 2.46806 12.7102 2.74273L12.71 2.74299C12.2644 3.24845 12 3.94779 12 4.69457C12 5.441 12.2641 6.14003 12.7094 6.64544C12.7096 6.64567 12.7098 6.64591 12.71 6.64614L17 11.4915L21.29 6.64614C21.2902 6.64594 21.2903 6.64575 21.2905 6.64555C21.7359 6.14013 22 5.44105 22 4.69457C22 3.94779 21.7356 3.24845 21.29 2.74299L22.0393 2.08231ZM12.7995 0.287073C12.1906 0.531914 11.6488 0.922166 11.2098 1.42032L12.7995 0.287073Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_47_997">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HandHeart;
